import { diffNow, formatISODate } from '../utils/date';

export class Application {
  constructor(id, applicationType, name, domain, environment, createdDate, sdkApiKey, lastEventTime, active, devKey, restKey) {
    this.id = id;
    this.applicationType = applicationType;
    this.name = name;
    this.domain = domain;
    this.environment = environment;
    this.createdDate = createdDate;
    this.sdkApiKey = sdkApiKey;
    this.lastEventTime = lastEventTime;
    this.active = active;
    this.devKey = devKey;
    this.restKey = restKey;
  }

  isPersisted() {
    return this.id !== undefined;
  }

  isTransient() {
    return this.id === undefined;
  }

  isWeb() {
    return this.applicationType === 'WEB';
  }

  isIOS() {
    return this.applicationType === 'IOS';
  }

  isAndroid() {
    return this.applicationType === 'ANDROID';
  }

  isHuaweiAndroid() {
    return this.applicationType === 'HUAWEI_ANDROID';
  }

  requiresDomain() {
    return this.isWeb();
  }

  getFormattedCreatedDate() {
    return formatISODate(this.createdDate);
  }

  getResolvedSdkKey() {
    if (this.sdkApiKey === undefined) {
      return 'YOUR-SDK-KEY will appear after save';
    }
    return this.sdkApiKey;
  }

  getStatusColor() {
    if (this.lastEventTime === undefined) {
      return 'danger';
    }
    if (diffNow(this.lastEventTime).minutes < 5) {
      return 'success';
    }
    return 'warning';
  }

  getInstallationCodeBlock() {
    if (this.isWeb()) {
      const code = `var xpPageLayer = {}; var __xennioSdkKey = '${this.getResolvedSdkKey()}'; var s = document.createElement('script'); s.setAttribute('src', 'https://s.xenn.io/xenn.io.min.js'); s.async = true; document.getElementsByTagName('script')[0].appendChild(s); var dataLayer = dataLayer || []; dataLayer.forEach(function (d) { Object.assign(xpPageLayer, d) });`;
      return `<script type="application/javascript">\n${code}\n</script>`;
    } if (this.isIOS()) {
      const code = `Xennio.configure(sdkKey: "${this.getResolvedSdkKey()}");`;
      return code;
    } if (this.isAndroid()) {
      const code = `Xennio.configure(this, "${this.getResolvedSdkKey()}");`;
      return code;
    }
    return '';
  }
}
