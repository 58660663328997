<template>
  <div>
    <base-header class="pb-6 pb-8 pt-2 pt-md-6">
      <b-row>
        <b-col cols="auto" class="mr-auto p-3">
        </b-col>
        <b-col cols="auto" class="p-3">
          <div class="content-top">
            <b-button variant="primary" @click="createNotification()">
              <b-icon icon="plus"></b-icon>
              Create New Application
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr class="p-0 m-0">
      <b-row>
        <b-col cols="4" v-for="each in applications" :key="each.id">
          <b-card class="mt-2">
            <b-row>
              <b-col cols="8" class="mr-auto p-1">
                <h6>{{ each.name }}</h6>
              </b-col>
              <b-col cols="auto" class="p-1 text-center">
                <b-badge class="ml-2" v-if="each.lastEventTime" :variant="getStatusColor(each)">Active</b-badge>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8" class="mr-auto p-1">
                <b-button class="m-2" size="sm" variant="outline-primary">
                  <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                  Settings
                </b-button>
              </b-col>
              <b-col cols="auto" class="p-1 text-center">
                <b-img class="application-icon" v-b-tooltip.hover :title="application(each.id).name"
                       :src="'/static/img/' + application(each.id).applicationType + '.svg'"/>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </base-header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import applicationService from '@/services/applications';
import ApplicationList from '@/components/applications/list.vue';
import { diffNow } from '@/utils/date';

export default {
  name: 'ApplicationCreateOrEdit',
  computed: {
    ...mapGetters(['application']),
  },
  components: {
    ApplicationList,
  },
  data: () => ({
    size: 20,
    currentPage: 1,
    pageCount: 0,
    total: 0,
    filters: {},
    applications: [],
    fields: ['name', 'createdDate', 'applicationType', 'environment', 'lastEventTime',
      {
        key: 'id',
        label: '',
      },
    ],
  }),
  created() {
    this.applicationsProvider();
  },
  methods: {
    async applicationsProvider() {
      this.filters.size = 21;
      this.filters.page = 0;
      const response = await applicationService.list(this.filters);
      this.$set(this, 'applications', response.results);
    },
    getStatusColor(application) {
      if (application.lastEventTime === undefined) {
        return 'danger';
      }
      if (diffNow(application.lastEventTime).minutes < 5) {
        return 'success';
      }
      return 'warning';
    },
  },
};
</script>
<style scoped>
.application-icon {
  width: 40px;
  height: 40px;
  float: left;
  margin-left: 2px;
}
</style>
