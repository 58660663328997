<template>
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Application Name</th>
      <th scope="col">Created Date</th>
      <th scope="col">SDK Type</th>
      <th scope="col">Application Environment</th>
      <th scope="col">Flow Status</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="application in applications" :key="application.id">
      <td>
        <router-link :to="{ name: 'ApplicationDetail', params: { id: application.id }}">
          {{ application.name }}
        </router-link>
      </td>
      <td>{{ application.createdDate | formatISODate }}</td>
      <td>{{ application.applicationType }}</td>
      <td>{{ application.environment }}</td>
      <td>
        <div class="custom-color">
          <div class="color-item">
            <label :class="['rounded-circle btn-'+ application.getStatusColor()]"></label>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
import { Application } from '../../models/application';

export default {
  name: 'ApplicationList',
  components: {},
  props: {
    applications: Array[Application],
  },
};
</script>
